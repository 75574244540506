.swiper {
  @apply w-full h-full relative;
  :global {
    --swiper-navigation-size: 64px;

    // Pagination
    .swiper-pagination {
      line-height: 0;
      @apply bottom-8 py-2;

      .swiper-pagination-bullet {
        @apply w-12 h-[5px] rounded-none bg-white opacity-50;
        margin: 0px !important;
        &.swiper-pagination-bullet-active {
          @apply opacity-100;
        }
      }
    }

    // Navigation
    .swiper-button-prev,
    .swiper-button-next {
      width: var(--swiper-navigation-size);
      @apply rounded-full bg-gray-700/20 opacity-0 transition-opacity select-none;
    }

    .swiper-button-prev {
      @apply left-3;
    }

    .swiper-button-next {
      @apply right-3;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      @apply w-4 h-4 block bg-cover;
      content: '';
      filter: invert(100%);
    }

    .swiper-button-prev::after {
      background-image: url('../../../../../../public/arrow-left.svg');
    }
    .swiper-button-next::after {
      background-image: url('../../../../../../public/arrow-right.svg');
    }
  }

  &:hover :global {
    .swiper-button-prev,
    .swiper-button-next {
      @apply opacity-100;
    }
  }
}
